<h1>{{data.title}}</h1>
<div class="content">
    <div>
        <mat-form-field>
            <mat-label>Filtre</mat-label>
            <input matInput [formControl]="filterControl" type="text" />
        </mat-form-field>
        <div class="option"><span (click)="selectAll()">Tout Selectionner</span> / <span (click)="unselectAll()">Aucun</span></div>
        <div class="list">
            <ng-container *ngFor="let control of display_list;let i=index;" >
            <mat-checkbox *ngIf="control.display" [formControl]='control.control'>{{control.title}}</mat-checkbox>
            </ng-container>
        </div>
    </div>
    <div>
        <div>
            {{count}} selectionné(s)
        </div>
        <div class="option"><span (click)="unselectAll(true)">Vider la selection</span></div>
        <div class="list">
            <ng-container *ngFor="let control of formArray.controls;let i= index;">
                <p *ngIf="control.value"><mat-icon (click)="control.setValue(false)" class="mdi mdi-12px mdi-close-circle"></mat-icon><span>{{data.list[i]}}</span><button mat-icon-button></button></p>
            </ng-container>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onSubmit()" mat-raised-button>Filtrer</button>
    <button mat-button mat-dialog-close color="warn">Annuler</button>
</div>