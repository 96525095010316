import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';
import { ExpertGuardService } from './services/expert-guard.service';
import { ConseilGuardService } from './services/conseil-guard.service';


const routes: Routes = [{
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuardService]
  },
  {
    path : "admin",
    loadChildren : () => import('./modules/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardService, AdminGuardService]
  },
  {
    path : "expert",
    loadChildren : () => import('./modules/expert.module').then(m => m.ExpertModule),
    canActivate: [AuthGuardService, ExpertGuardService]
  },
  {
    path : "conseil",
    loadChildren : () => import('./modules/conseil.module').then(m => m.ConseilModule),
    canActivate: [AuthGuardService, ConseilGuardService]
  },
  {
    path : "statistics",
    loadChildren : () => import('./modules/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  { path: '**', redirectTo:'unauthorized' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
