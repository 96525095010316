import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, tap, switchMap} from 'rxjs/internal/operators';

@Injectable({providedIn : 'root'})
export class ExpertGuardService implements CanActivate, CanLoad {

    private unauthorized_page = "/unauthorized";

    constructor(private authenticationService: AuthenticationService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            return this.checkRoles();
        }
    canLoad(route: Route): Observable<boolean>| boolean {
        return this.checkRoles();
    }

    checkRoles()
    {
        return this.authenticationService.isLogged && this.authenticationService.user.roles.find(r => r.type === 'expert') !== undefined;
    }


}
