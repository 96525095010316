<div class="popin-wrapper">
    <div mat-dialog-title>
        <span>Données non sauvegardées</span>
        <button mat-icon-button disableRipple="true" [mat-dialog-close]="false">
            <mat-icon class="mdi mdi-24px mdi-close"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <span>Vous avez modifié des données! Si vous quittez la page, vos modifications seront perdues.</span>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button color="warn" [mat-dialog-close]="false">
            <mat-icon class="mdi mdi-24px mdi-close"></mat-icon>
            <span class="btn-text">Rester</span>
        </button>
        <button (click)="onSubmit()" mat-raised-button color="accent">
            <mat-icon class="mdi mdi-24px mdi-check"></mat-icon>
            <span class="btn-text">Quitter</span>
        </button>
    </div>
</div>
