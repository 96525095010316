import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-prod-check',
  templateUrl: './prod-check.component.html',
  styleUrls: ['./prod-check.component.scss']
})
export class ProdCheckComponent implements OnInit {

  public current_url:string = "";



  @Input()
  public prod_url:string = "";



  public action:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.current_url = window.location.origin;
  }

  public close()
  {
    this.action = true;
  }

}

@NgModule({
  declarations : [ProdCheckComponent],
  exports : [ProdCheckComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]

})
export class ProdCheckModule
{

}