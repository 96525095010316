import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';

export interface Theme {
    value: string;
    viewValue: string;
}

@Injectable({providedIn: 'root'})
export class ThemeService {

    private _themes: Theme[] = [
        {value: 'default-theme', viewValue: 'default'},
    ];

    private _theme: Subject<Theme> = new Subject<Theme>();

    public getThemes() {
        return this._themes;
    }

    public getDefaultTheme(): Theme {
        return this._themes[0];
    }

    public getTheme(): Observable<Theme> {
        return this._theme;
    }

    public setTheme(selectedTheme: string) {
        const _theme = _.find(this._themes, {value: selectedTheme});
        this._theme.next(_theme);
    }
}
