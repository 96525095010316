import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _load:Subject<{url:string, status : boolean}> = new Subject();

  constructor() { }


  public show(url:string)
  {
    this._load.next({url, status : true});
  }
  
  public hide(url:string)
  {
    this._load.next({url, status : false});
  }

  public load()
  {
    return this._load;
  }
}
