import {NgModule} from '@angular/core';
import {MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MatFileUploadModule} from "./mat-file-upload/mat-file-upload.component";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule  } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import {ClipboardModule} from '@angular/cdk/clipboard';


export const MY_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'YYYY-MM-DD',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  

@NgModule({
    imports: [
      ClipboardModule,
        MatDatepickerModule,
        ScrollingModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatRadioModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatFileUploadModule,
        DragDropModule
    ],
    exports: [
        MatDatepickerModule,
        ScrollingModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatRadioModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatFileUploadModule,
        DragDropModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },
          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}

    ]
})
export class MaterialModule { }
