import {OverlayContainer} from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Theme, ThemeService } from './services/theme.service';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public theme: Theme;
  public themes: Theme[] = [];

  constructor(
      private themeService: ThemeService,
      public authService:AuthenticationService,
      private overlayContainer: OverlayContainer
  ) {
    this.theme = this.themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  public ngOnInit() {
    this.themes = this.themeService.getThemes();
    this.themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });

  }


  private overlayContainerTheming(themeClassName: string) {
    const cl = this.overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
    }

    this.overlayContainer.getContainerElement().classList.add(themeClassName);
  }
}
