import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-filters',
  templateUrl: './dialog-filters.component.html',
  styleUrls: ['./dialog-filters.component.scss']
})
export class DialogFiltersComponent implements OnInit {

  public formArray:FormArray;
  public count = 0;
  public filterControl:FormControl;
  public display_list = [];


  constructor(public dialogRef: MatDialogRef<DialogFiltersComponent>, @Inject(MAT_DIALOG_DATA) public data:{title : string, list:Array<String>, formArray:FormArray}) { 
    this.formArray = this.data.formArray;
    this.display_list = this.formArray.controls.map((e, i) => {
      return {display : true, control : e, title: this.data.list[i]};
    })

    this.filterControl = new FormControl("");
    this.initEvent();
  }

  

  private initEvent()
  {
    this.filterControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((e:string) => {
      if (!e)
        e = '';
      e = e.toLowerCase();
      this.display_list.forEach((ee) => {
        if (ee.title.toLowerCase().indexOf(e) !== -1)
          ee.display = true;
        else
          ee.display = false;
      })
    })

    this.formArray.controls.forEach(e => {
      this.count += e.value ? 1 : 0;
      e.valueChanges.subscribe(e => {
        if (e)
          this.count++;
        else
          this.count--; 
      })
    })
  }
  selectAll()
  {
    this.formArray.controls.forEach((e, i) => {
      if (this.display_list[i].display)
      {
        if (!e.value)
        ++this.count;
        e.setValue(true, {emitEvent : false})
      }
    });
  }
  unselectAll(selection = false)
  {
    this.formArray.controls.forEach((e, i) => {
      if (selection || this.display_list[i].display)
      {
        if (e.value)
         --this.count;
 
        e.setValue(false, {emitEvent : false})}
      }
        );
 
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

}
