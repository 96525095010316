import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router:Router, private authService:AuthenticationService) { }

  ngOnInit(): void {
    this.router.navigate(['/' + (this.authService.user.roles[0].type == "stat" ?'statistics' :this.authService.user.roles[0].type)])

  }

}
