<app-prod-check prod_url="https://skuld.iphub.me"></app-prod-check>
<div [ngClass]="theme.value" cdkOverlayOrigin>

  <div id="global-content" [ngClass]="{loading : !authService.isLogged}">
    <mat-toolbar *ngIf="authService.isLogged">
      <mat-toolbar-row>
        <button mat-button routerLink="/" [disableRipple]="true">
          <span><img src="/assets/logo_skuld.png"/></span>
        </button>

        <span class="spacer"></span>

        <!--<button [routerLink]="[ '/notifications']" mat-button id="notification">
          <mat-icon class="mdi mdi-32px mdi-bell"></mat-icon>
          <span class="count-notification" [ngClass]="nb_notifications > 9 ? 'count-large' : 'count-short'" >{{nb_notifications > 9 ? '+9' : nb_notifications}}</span>
        </button>-->
          <div class="profile" mat-button>
          <div class="profile-wrapper">
            <div class="profile-details">
              <span>{{authService.user?.firstname}} {{authService.user?.lastname}}</span>
              <span>{{authService.user?.job}}</span>
            </div>

            <div class="profile-picture">
              <img *ngIf="authService.user.photo" src="data:image/jpeg;base64,{{authService.user?.photo}}">
              <div *ngIf="!authService.user.photo" class="list-user-picture-anonymous"><span>?</span></div>
            </div>
          </div>
        </div>

        <div class="separator"></div>

        <button (click)="authService.logout()" mat-button [disableRipple]="true">
          <mat-icon class="mdi mdi-32px mdi-power"></mat-icon>
        </button>

      </mat-toolbar-row>
    </mat-toolbar>
    <mat-sidenav-container>
      <mat-sidenav [opened]="authService.isLogged" mode="side">
        <div class="menu" *ngIf="authService.isLogged">
          <div class='item' [routerLinkActive]="'select'" [routerLink]="['/admin']" *ngIf="authService.user.isAdmin" >
            <button matTooltip="Administration" mat-icon-button [disableRipple]="true">
              <mat-icon class="mdi mdi-32px mdi-cog"></mat-icon>
            </button>
            <span>Administation</span>
          </div>
          <div class='item' [routerLinkActive]="'select'" [routerLink]="['/expert']" *ngIf="authService.user.isExpert" >
            <button   matTooltip="Expertise"  mat-icon-button [disableRipple]="true">
              <mat-icon class="mdi mdi-32px mdi-crosshairs-gps"></mat-icon>
            </button>
            <span>Expert</span>
          </div>
          <div class='item' [routerLinkActive]="'select'" [routerLink]="['/conseil']" *ngIf="authService.user.isConseil" >
            <button     matTooltip="Conseil" *ngIf="authService.user.isConseil" mat-icon-button [disableRipple]="true">
              <mat-icon class="mdi mdi-32px mdi-account-supervisor"></mat-icon>
            </button>
            <span>Conseil</span>
          </div>
          <div class="item" [routerLinkActive]="'select'" [routerLink]="['/statistics']" *ngIf="authService.user.isConseil || authService.user.isExpert || authService.user.isStat " >
            <button    matTooltip="Statistiques" mat-icon-button [disableRipple]="true">
              <mat-icon class="mdi mdi-32px mdi-chart-line"></mat-icon>
            </button>
            <span>Statistiques</span>
          </div>
      </div>
    </mat-sidenav>
      <mat-sidenav-content>
        <div id="router-content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    
  </div>
</div>
