import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './libraries/material.module';
import { MsalModule } from '@azure/msal-angular';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuardService } from './services/auth-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CanvasComponent } from './libraries/canvas/canvas.component';
import { JwtInterceptor } from './services/jwt-interceptor.service';
import { UnSaveComponent } from './libraries/unsave/unsave.component';
import { VideoDialogComponent } from './libraries/video-dialog/video-dialog.component';
import { DialogFiltersComponent } from './libraries/dialog-filters/dialog-filters.component';
import {  ProdCheckModule } from './libraries/prod-check/prod-check.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UnSaveComponent,
    UnauthorizedComponent,
    CanvasComponent,
    VideoDialogComponent
  ],
  imports: [
    ReactiveFormsModule,
    ProdCheckModule,
    HttpClientModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [UnSaveComponent, VideoDialogComponent, DialogFiltersComponent]
})
export class AppModule { }
