import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, tap, switchMap} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {

    private login_page = '/';
    private unauthorized_page = "/unauthorized";

    constructor(private authenticationService: AuthenticationService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.checkLogin(state.url, next.queryParams['access_token']);
    }
    canLoad(route: Route): Observable<boolean>| boolean {

        return this.authenticationService.isLogged;
      }

      checkLogin(url: string,  access_token : string): Observable<boolean> | boolean {

        if (this.authenticationService.isLogged)
            return true;
        if (url != this.login_page)
            this.authenticationService.lastUrl = url.replace(/access_token=[^&]+/, '');

        if (access_token)
          this.authenticationService.access_token = access_token;
        return this.authenticationService.checkAuthentication().pipe(tap((e:any) => {
          if (e === null)
            return;
          if (!e)
            this.router.navigate([this.unauthorized_page]);
          else
            this.router.navigateByUrl(this.authenticationService.lastUrl);
        }), map((e:any) => e === null ? false : !!e));
      }
}
