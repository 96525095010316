import {Injectable} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';
import {  finalize, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class  JwtInterceptor implements HttpInterceptor {
  constructor(private loaderService:LoaderService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt_token = localStorage.getItem('access_token');

    if (jwt_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt_token}`
        }
      });
    }
    this.loaderService.show(request.url);
    return next.handle(request).pipe(
      finalize(() =>{
      this.loaderService.hide(request.url);
    }));
  }
}
