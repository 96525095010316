import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from "@angular/router";
import {MsalService, BroadcastService} from "@azure/msal-angular";
import {catchError,  map, tap} from 'rxjs/internal/operators';
import {BaseService} from './base.service';
import { of, throwError, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthenticationService extends BaseService {

    public authUrl = "https://auth.iphub.me/login";
    public isLogged = false;
    public lastUrl = "/";
    public error: string;
    public user: any;
    public access_token = undefined;

    constructor(private http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
        super(router, snackBar);
    }

  public authenticate()
  {
    let url = new URL( window.location.href);
    let httpParam:HttpParams = new HttpParams().set("redirect", window.origin + url.pathname).set('query', url.searchParams!.toString()).set('scope', "user.readbasic.all");
    window.location.href = this.authUrl + "?" + httpParam.toString();

  }


  public isDelegate(type:string)
    {
        return this.user.roles.find(e => e.type == type && !e.isDelegate) === undefined;
    }

    private processRoles()
    {
        this.user.isAdmin = this.user.roles.find(r => r.type === 'admin') !== undefined
        this.user.isConseil = this.user.roles.find(r => r.type === 'conseil') !== undefined
        this.user.isExpert = this.user.roles.find(r => r.type === 'expert') !== undefined
        this.user.isStat = this.user.roles.find(r => r.type === 'stat') !== undefined
    }

    public reload():Observable<any>
    {
        return this.http.get('/api/auth/reload').pipe(tap(e => {
            this.user.roles = e['roles'];
            this.user.token = e['token'];
            this.processRoles();
            localStorage.setItem('access_token', this.user.token);
        }), catchError(e => this.handleError(e)));
    }



    public checkAuthentication(): Observable<any> {
      if (!this.access_token)
      {
        this.authenticate();
        return of(null);
      }
      return this.http.post('/auth/login', {
          access_token: this.access_token
        }).pipe(
          catchError((err) => {
            this.access_token = undefined;
            if (err.status == 403) {
              this.authenticate();
            }
            this.isLogged = false;
            return of(false);
          }),
          map((response: any) => {
            if (response) {
              this.user = response;
              this.processRoles();
              localStorage.setItem('access_token', response.token);
              this.isLogged = true;
              return true;
            } else {
              return false;
            }
          })
        );
      }

    public logout() {
        localStorage.removeItem("access_token");
        this.access_token = null;
        this.isLogged = false;

        this.router.navigate(['']);
        return of(true);
    }
}
