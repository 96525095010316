import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-unsave',
    templateUrl: './unsave.component.html',
    styleUrls: ['./unsave.component.scss']
})
export class UnSaveComponent{

    constructor(
        public dialogRef: MatDialogRef<UnSaveComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public onSubmit()
    {
        this.dialogRef.close(true);
    }
}
